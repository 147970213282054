import React from 'react'
import { Box } from '@chakra-ui/react'

// @ts-ignore
export default function MainBox({ children }) {
  return (
    <Box bg={'#f8f8f8'} pb={{ base: '32px', md: '196px' }}>
      {children}
    </Box>
  )
}
