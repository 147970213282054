import React from 'react'
import { Card, CardHeader, Container, Heading, Link } from '@chakra-ui/react'
import { CardBody } from '@chakra-ui/card'
import MainBox from '@/components/MainBox'

export default function FourOhFour() {
  return (
    <MainBox>
      <Container maxW={'container.xl'} pt="10">
        <Card py="4" variant="outline" px={4}>
          <CardHeader>
            <Heading>😞 Page non trouvée</Heading>
          </CardHeader>
          <CardBody>
            <Link href="/">Retourner à l&apos;accueil</Link>
          </CardBody>
        </Card>
      </Container>
    </MainBox>
  )
}
